const reviews = [
    {
      id: 1,
      name: 'Transnet Projects',
      job: 'Repairs to Sentrarand yard Road',
      contact: 'Mr. Thakhani Shai - 083 397 0003',
      
      
    },
    {
      id: 2,
      name: 'Metrorail',
      job: 'Supply and installation of Carpet Tiles.r',
      contact: 'Mr. M Mthini - 011 773 4351',
      
     
    },
    {
      id: 3,
      name: 'Old Mutual ',
      job: 'Refurbishment of lift walls.',
      contact: 'Mr. Pheeko Moatshe - 072 046 7954',
      
     
    },
    {
      id: 4,
      name: 'Johanneburg Roads Agency',
      job: 'Slope Rehabilitation at Four-ways.',
      contact: 'Mr Hendrik van Tonder - 083 978 4920',
      
     
    },
    {
      id: 5,
      name: 'Department of Health Free State',
      job: 'Construction of Relebohile Clinic in Hilbron.',
      contact: 'Mr. L Penze - 051 408 1415',
     
      
    },
    {
      id: 6,
      name: 'Joshua Maroge Quantity Survetors',
      job: 'Repairs and maintance of planning department offices.',
      contact: 'Joshua Maroge - 015 295 3771',
      
    },
    {
      id: 7,
      name: 'Botsang & Associates (pty) Ltd',
      job: 'Construction of Walkway in Pelonomi Hospital.',
      contact: 'Mr. Thebe Chwene - 084 953 1362 / 011 472 1785',
      
    },
    {
      id: 8,
      name: 'Botsang & Associates (pty) Ltd',
      job: 'Construction of Zamdela Clinic.',
      contact: 'Mr. Thebe Chwene - 084 953 1362 / 011 472 1785',
     
      
    },
    {
      id: 9,
      name: 'Botsang & Associates (pty) Ltd',
      job: 'Construction of Schonkenville clinic.',
      contact: 'Mr. Thebe Chwene - 084 953 1362 / 011 472 1785',
     
      
    }
    
  ];
  
  export default reviews;