import React from 'react';

import Navbar from 'react-bootstrap/Navbar'; 
import Nav from 'react-bootstrap/Nav';
import NavLink from 'react-bootstrap/NavLink';
import Container from 'react-bootstrap/Container';

import Button from '../../components/button/button.component';

import './header.styles.css';

const Header = () => {
    return(
        <div className='header'>

            <Navbar fixed="top" className='navi' collapseOnSelect expand="lg" >
                <Container>
                    <Navbar.Brand > <img className='image' src={window.location.origin + '/images/leseme.svg'} alt="contact" /></Navbar.Brand>
                    <Navbar.Toggle  aria-controls="responsive-navbar-nav"  className="custom-toggler navbar-toggler ml-auto" />
                        <Navbar.Collapse id="responsive-navbar-nav ">
                            <Nav className="test text-center">
                            <NavLink className='link' href='#about-section'>ABOUT US</NavLink>
                            <NavLink className='link' href='#services-section'>SERVICES</NavLink>
                            <NavLink className='link' href='#projects-section'>PROJECTS</NavLink>
                            <NavLink className='link' href='#contact-us-section'>GET IN TOUCH</NavLink>
                            
                            </Nav>
                            <div className='text-center'>
                            <a href="images/LESEME-PROFILE.pdf" download>
                                <Button className='main-button'>Download our profile</Button>
                             </a>
                            </div>
                        </Navbar.Collapse>
                </Container>
            </Navbar>

        </div>
    );
}

export default Header;