import  HomePage from './pages/home-page/home-page.component';

function App() {
  return (
    <div className="App">
     <HomePage/>
    </div>
  );
}

export default App;
