import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import './contact.styles.css';




const Contact = () => {
    return(
        <div className='contact main-sec' id='contact-us-section'>
            <Container>
                    <Row>
                        <Col><h1 className='heading'>Get in touch</h1></Col>
                    </Row>
                    <Row>
                        <Col><h4 className='under-head'>If you have any questions get in touch with us</h4></Col>
                    </Row>

              
               

                           <Row className='text-center mb-5'>
                                <Col sm={12} md={4} lg={4} xl={4}><h5 className='text-muted'>Tel: 011 958 2403</h5></Col>
                                <Col sm={12} md={4} lg={4} xl={4}><h5 className='text-muted'>Fax: 011 958 1318</h5></Col>
                                <Col sm={12} md={4} lg={4} xl={4}><h5 className='text-muted'>Email: Admin@leseme.co.za</h5></Col>
                           </Row>
              

                    <Row>
                        <Col sm={12} md={12} lg={12} xl={12}>
                            <iframe title='googlemaps' style={{width:'100%', height:'400px'}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1791.4726276613999!2d27.872567600543526!3d-26.100705666328096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e959efe9290a599%3A0xceda5109d3d09d23!2sRuimsig%20View%20Estate!5e0!3m2!1sen!2sza!4v1623067080716!5m2!1sen!2sza" ></iframe>
                        </Col>

                        
                    </Row>

            </Container>
        </div>
    );
}

export default Contact;