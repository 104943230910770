import React from 'react';

import './button.styles.css';

const Button = ({children, ...otherProps}) => (
    <div className='button'>
        <button className={'button-style'} {...otherProps}> {children}</button>
    </div>
);

export default Button;