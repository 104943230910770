import React from 'react';

import  Header from '../../components/header/header.component';
import LandingPage from '../../components/landing-page/landing-page.component';
import AboutUs from '../../components/about-us/about-us.component';
import Jumbo from  '../../components/jumbo/jumbo.compoent';
import Services from '../../components/services/services.component';
import Projects from '../../components/projects/projects.component';
import Contact from '../../components/contact/contact.component';
import Footer from '../../components/footer/footer.component';


const HomePage = () => {
    return (
        <div className='home-page'>
            <Header/>
            <LandingPage/>
            <AboutUs/>
            <Jumbo/>
            <Services/>
            <Projects/>
            <Jumbo/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default HomePage;