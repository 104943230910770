import React, {useState} from  'react';

import people from './data';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Badge from 'react-bootstrap/Badge'

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';


import './projects.styles.css';

const Projects = () => {

  const [index, setIndex] = useState(0);
  const{name, job, contact} = people[index];

  const checkNum = (number) => {
    if ( number > people.length -1){
        return 0;

    }
    if ( number < 0 ){
        return people.length - 1;
    }

    return number;
  }

  const prevPerson = () =>{
        setIndex(() => {
            let newIndex = index - 1;
            return checkNum(newIndex);
        })
    }


  const nextPerson = () =>{
    setIndex(() => {
        let newIndex = index + 1;
        return checkNum(newIndex);
    })
  }



  
    return(
        <div className='projects main-sec' id='projects-section'>
            <Container>
                    <Row>
                        <Col><h1 className='heading'>Projects</h1></Col>
                    </Row>
                    <Row>
                        <Col><h4 className='under-head'>These are some reviews of projects we have worked on.</h4></Col>
                    </Row>

                    <Row>
                        <Col>

                            <article className='review '>
    
                                <h3 className='author'>{name}</h3>
                                <h4 className='job'>{job}</h4>
                                <h5 className='contact'>{contact}</h5>
                                <Badge className='badge' variant="secondary">Status - Completed</Badge>
                                
                                <div className='button-container'>
                                    <button className='prev-btn' onClick={prevPerson}>
                                        <FaChevronLeft/>
                                    </button>

                                    <button className='next-btn' onClick={nextPerson}>
                                        <FaChevronRight/>
                                    </button>
                                </div>
                            </article>
                        
                        </Col>
                    </Row>
            </Container>
        </div>
    );
}

export default Projects;