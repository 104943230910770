import React from 'react';

import './footer.styles.css';

import {AiFillFacebook} from 'react-icons/ai';
import {AiFillInstagram} from 'react-icons/ai';
import {AiFillTwitterSquare} from 'react-icons/ai';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Footer = () => {
    var d = new Date();

    return (
        <div className='footer'>
            <footer  className="page-footer text-center ">
                <Container >
                
                    <Row >
                        <Col>
                        <a href="#"><AiFillFacebook  className="ico1 mt-5  text-center"/></a>
                        <a href="#"><AiFillInstagram  className="ico1 mt-5  text-center"/></a>
                        <a href="#"><AiFillTwitterSquare  className="ico1 mt-5 text-center" /></a>
                        </Col>
                    </Row>



                    <Row>
                        <Col>
                        <p className='footing'> ALL RIGHTS RESERVED &copy; {d.getFullYear()} LESEME CONSTRUCTION (PTY) LTD</p>
                        </Col>
			
                    </Row>
<Row>
<p>Website Designed by Softlife IT Solutions.</p>
</Row>
                </Container>
            </footer>

        </div>
    );
}


export default Footer;