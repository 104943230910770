import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import './about-us.styles.css';

const AboutUs = () => {
    return(
        <div className='about-us main-sec ' id='about-section'>
            <Container>
                <Row>
                    <Col><h1 className='heading'>About us</h1></Col>
                </Row>
                <Row>
                    <Col><h4 className='under-head'>Who we are and what we do.</h4></Col>
                </Row>

                <Row>
                    <Col>
                        <h5 className='text-muted text-center mb-3'>
                            Leseme Construction Pty Ltd was formed with a goal of providing engineering and 
                            general building services with high standards of delivery and quality. It is owned by
                            Mr. Motshedi Nathane. Mr. Nathane has substantial experience in Civil and 
                            Structural Engineering, administration and labour matters, and he also has a B Tech 
                            in Civil Engineering from the University of Johannesburg. The company has 
                            appointed Ms. Phindile Ngwenya as Operations Manager. The company has links 
                            with established companies to provide additional capacity and expertise with regard 
                            to projects in order to provide the best available deliverables to our clients. Ms.
                            Phindile Ngwenya brings into this firm the following credentials, National Diploma in Civil Engineering, University of Johannesburg and 
                            Project management skills with Effective Interaction.
                        </h5>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <h5 className='text-muted text-center mb-5'>
                        Ms. Phindile Ngwenya has more than 6 years of experience in Civil Engineering 
                        Construction and Building Construction.
             
                        </h5>
                    </Col>
                </Row>
                
              
            </Container>
        </div>
    )
}

export default AboutUs;