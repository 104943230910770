import React from 'react';
import  Container  from 'react-bootstrap/Container';



import './landing-page.styles.css';

const LandingPage = () => {
    return (
        <div className ='landing-page ' style={{ backgroundImage: `url('/images/landing-page.jpg')` }}>
            <Container>
            <div className="landingmain mt-5 ">
                <h1 className='heading-landing mb-5'><img className='image-land' src={window.location.origin + '/images/leseme.svg'} alt="contact" /></h1>
                <h2 className='under-head-2'>Providing engineering and general building services with high standards of delivery</h2>
            </div>
            </Container>
        </div>
    );
}

export default LandingPage;