import React from 'react';

import './jumbo.styles.css';

const Jumbo = () => {
    return (
        <div className='jumbo' style={{ backgroundImage: `url('/images/landing-page.jpg')` }}>

        </div>
    );
}

export default Jumbo;