import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion'

import {BiCylinder} from 'react-icons/bi';


import './services.styles.css';

const  Services = () => {
    return (
        <div className='services main-sec' id ='services-section'>
            <Container>
                <Row>
                    <Col><h1 className='heading'>Services</h1></Col>
                </Row>
                <Row>
                    <Col><h4 className='under-head'>Our core business is construction and maintenance of the following</h4></Col>
                </Row>

                <Row>
                    <Col sm={12} md={4} lg={4} xl={4}>
                        <Card className='service text-center'>
                            <Card.Body>
                            <BiCylinder className='icon'/>
                            <Card.Title>STEEL WORKS</Card.Title>
                                <Accordion className='accord'>
                                    <Card className='service'>
                                        <Card.Header className='service'>
                                        <Accordion.Toggle className='service'  as={Card.Header} variant="link" eventKey="0">
                                            View info
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                        <Card.Body className='bod'>
                                            <Row><span className='text-muted'>&#x26AC; Palisade fencing</span></Row>
                                            <Row><span className='text-muted'>&#x26AC; Palisade gates</span></Row>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col sm={12} md={4} lg={4} xl={4}>
                        <Card className='service text-center'>
                                <Card.Body>
                                <BiCylinder className='icon'/>
                                <Card.Title>ROADS AND PAVEMENTS</Card.Title>
                                    <Accordion className='accord'>
                                        <Card className='service'>
                                            <Card.Header className='service'>
                                            <Accordion.Toggle className='service'  as={Card.Header} variant="link" eventKey="0">
                                                View info
                                            </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                            <Card.Body className='bod'>
                                                <Row><span className='text-muted'>&#x26AC; Pavement design for urban and rural roads</span></Row>
                                                <Row><span className='text-muted'>&#x26AC; Pavement maintenance and rehabilitation for urban and rural roads</span></Row>
                                            </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Card.Body>
                            </Card>
                    </Col>

                    <Col sm={12} md={4} lg={4} xl={4}>
                        <Card className='service text-center'>
                                <Card.Body>
                                <BiCylinder className='icon'/>
                                <Card.Title>BRIDGES AND CULVERTS</Card.Title>
                                    <Accordion className='accord'>
                                        <Card className='service'>
                                            <Card.Header className='service'>
                                            <Accordion.Toggle className='service'  as={Card.Header} variant="link" eventKey="0">
                                                View info
                                            </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                            <Card.Body className='bod'>
                                                <Row><span className='text-muted'>&#x26AC; Inspection of existing bridges/culverts</span></Row>
                                                <Row><span className='text-muted'>&#x26AC; Rehabilitation of existing steel and concrete bridges/culverts</span></Row>
                                            </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Card.Body>
                            </Card>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} md={4} lg={4} xl={4}>
                        <Card className='service text-center'>
                            <Card.Body>
                            <BiCylinder className='icon'/>
                            <Card.Title>STORMWATER</Card.Title>
                                <Accordion className='accord'>
                                    <Card className='service'>
                                        <Card.Header className='service'>
                                        <Accordion.Toggle className='service'  as={Card.Header} variant="link" eventKey="0">
                                            View info
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                        <Card.Body className='bod'>
                                            <Row><span className='text-muted'>&#x26AC; Erosion control and analysis</span></Row>
                                            <Row><span className='text-muted'>&#x26AC; Stormwater pipelines</span></Row>
                                            <Row><span className='text-muted'>&#x26AC; Flood control measures</span></Row>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col sm={12} md={4} lg={4} xl={4}>
                        <Card className='service text-center'>
                                <Card.Body>
                                <BiCylinder className='icon'/>
                                <Card.Title>EARTHWORKS</Card.Title>
                                    <Accordion className='accord'>
                                        <Card className='service'>
                                            <Card.Header className='service'>
                                            <Accordion.Toggle className='service'  as={Card.Header} variant="link" eventKey="0">
                                                View info
                                            </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                            <Card.Body className='bod'>
                                                <Row><span className='text-muted'>&#x26AC; Deep excavations</span></Row>
                                                <Row><span className='text-muted'>&#x26AC; Bulk earthworks</span></Row>
                                                <Row><span className='text-muted'>&#x26AC; Slope stability</span></Row>
                                                <Row><span className='text-muted'>&#x26AC; Shoring/sheet piling</span></Row>
                                            </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Card.Body>
                            </Card>
                    </Col>

                    <Col sm={12} md={4} lg={4} xl={4}>
                        <Card className='service text-center'>
                                <Card.Body>
                                <BiCylinder className='icon'/>
                                <Card.Title>HOUSING AND CONSTRUCTION</Card.Title>
                                    <Accordion className='accord'>
                                        <Card className='service'>
                                            <Card.Header className='service'>
                                            <Accordion.Toggle className='service'  as={Card.Header} variant="link" eventKey="0">
                                                View info
                                            </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                            <Card.Body className='bod'>
                                                <Row><span className='text-muted'>&#x26AC; labour based/ intensive project type</span></Row>
                                                <Row><span className='text-muted'>&#x26AC; Palisade fencing</span></Row>
                                                <Row><span className='text-muted'>&#x26AC; Tiling</span></Row>
                                                <Row><span className='text-muted'>&#x26AC; Paving</span></Row>
                                                <Row><span className='text-muted'>&#x26AC; Painting</span></Row>
                                            </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Card.Body>
                            </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Services;

